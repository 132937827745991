<template>
  <div v-click-outside="clickOutside" ref="boxEl" class="star-box-inner" :class="{ openstar: openBox }">
    <div class="game-inner-box star">
      <div class="clickmebox" @click="emitToggle"></div>
      <img :src="thumbnail.imageURL" class="star-img" />
      <h4 @click="emitToggle" class="star-name">{{ name }}</h4>
      <div class="subs-wrap">
        <img v-if="youtube" src="../assets/redtube.svg" class="yt-logo" />
        <!-- Change this logo to instagram -->
        <img v-if="instagramFollowers" src="../assets/insta-red.svg" class="yt-logo" />
        <img v-if="tickTokFollowers" src="../assets/tiktok-red.svg" class="" />
        <p class="subs">{{ youtube || instagramFollowers || tickTokFollowers }}</p>
      </div>
      <!-- <div class="logo-links-wrap">
        <a
          v-for="platform in platforms"
          :key="platform._id"
          href=""
          class="logo-link"
          target="_blank"
        >
          <img :src="platform.platformLogo[0].item.logo.imageURL" />
        </a>
      </div> -->
      <div class="list-drop-tri star"></div>
    </div>
    <transition>
      <div v-show="openBox" class="list-drop-box game" :id="`s${_id}`">
        <img v-show="bigImage && bigImage.imageURL" :src="bigImage.imageURL" class="game-big-img" />
        <div class="game-drop-txt-wrap">
          <h4 class="txt60 blue-txt">{{ name }}</h4>
          <div class="txt18" v-html="content"></div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "gamebox",
  props: {
    openVal: { type: Boolean, default: undefined },
    thumbnail: { type: Object, default: undefined },
    name: { type: String, default: undefined },
    youtube: { type: String, default: undefined },
    instagramFollowers: { type: [String, Number], default: undefined },
    tickTokFollowers: { type: [String, Number], default: undefined },
    platforms: { type: Array, default: undefined },
    _id: { type: String, default: undefined },
    bigImage: { type: Object, default: undefined },
    content: { type: String, default: undefined },
  },
  data: () => ({
    openBox: false,
  }),
  watch: {
    openVal: {
      handler(newVal) {
        this.openBox = newVal;
      },
    },
    openBox(newVal) {
      if (!newVal) return;
      this.scrollToEl();
    },
  },
  methods: {
    clickOutside() {
      if (!this.openBox) return;
      this.$emit("toggleOff");
    },
    scrollToEl() {
      this.$nextTick(() => {
        const el = this.$refs.boxEl;
        el.scrollIntoView({ top: 0, behavior: "smooth" });
      });
    },
    emitToggle() {
      const openBox = !!this.bigImage && !this.openBox;
      this.$emit("toggleOpen", {
        openVal: openBox,
        _id: this._id,
      });
    },
  },
};
</script>
